import React from 'react';
import {
  Title,
  Hero,
  Description,
  LayoutBlock,
  Container,
  Column,
  Section,
  Row,
  Button,
  Layout,
  Block,
  Actions,
} from '@dragonchain-dev/dragon-kit';
import { Knowledgeperk } from '@dragonchain-dev/dragon-kit-icons';
import { Layout as PageLayout, SEO, SolutionRows } from '../../components/_index';

const Solutions = () => {
  return (
    <PageLayout>
      <SEO
        title="Blockchain Powered Applications | Dragonchain"
        description="Build enterprise and startup friendly blockchain powered applications with Dragonchain."
      />
      <Hero
        type="code"
        backgroundImage="https://images.dragonchain.com/texture/clouds-blue@2x.jpg"
        video={{
          url: 'https://www.youtube.com/watch?v=YPXc3AWR1Ys',
          autoPlay: true,
        }}
      >
        <Title color="white">Enterprise Ready, Startup Friendly</Title>
        <Description color="blue-light">
          <p>
            Use your existing staff with no blockchain experience to code in any language and deploy to any
            cloud platform. From startup to enterprise, Dragonchain has proven scalability for your business.
          </p>
          <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
        </Description>
      </Hero>
      <Layout>
        <Container>
          <Row gap="small">
            <Column span={12}>
              <Section subtitle="Protection of business data at the core" title="Security">
                Protection of business data is one of the primary reasons that Disney originally created Dragonchain. Every transaction is
                secured by billions of dollars in measurable proof with the combined security of Bitcoin, Ethereum, and other Interchain networks.
              </Section>
            </Column>
          </Row>
          <Row gap="small">
            <Column span={12}>
              <Section subtitle="The threat of quantum computing is here" title="Quantum Safety">
              A business can harness our latest Quantum Safe products to safeguard their sensitive data well into the future - Encryption by Aqed.
              </Section>
            </Column>
          </Row>
          <Row gap="small">
            <Column span={12}>
              <Section
                subtitle="Leverage the expertise of industry leaders within the blockchain space"
                title="Consulting and Blockchain Development Services"
              >
                The Dragonchain Consulting team will help you implement blockchain solutions to address your real-world business problems. We offer blockchain development of PoC and production systems, as well as whitepaper services. Our consulting service is your key to success.
              </Section>
            </Column>
          </Row>
        </Container>
      </Layout>
      <LayoutBlock>
        <Container>
          <Row gap="small" span={8}>
            <Column>
              <Section caption="Proof Systems by Dragonchain" title="Solutions Ready For Your Business">
                Dragonchain has business-ready applications and developer friendly integrations to better
                serve your business.
              </Section>
            </Column>
          </Row>
          <SolutionRows />
          <Layout dark background="blue">
            <Block center stack style={{ padding: 0 }}>
              <div className="icon">
                <Knowledgeperk color="white" />
              </div>
              <Title tag="p" size="small" color="blue-light" weight="300">
                {`"I had no idea the technology piece could allow us to go from
            absolutely nothing, to something that is being used in our store
            right now -- today; in 4 days."`}
              </Title>
              <Description>Ryan Sanderson, Knowledge Perk CEO</Description>
              <br />
              <Button color="white" hover="blue" to="/blockchain-platform/data-integration">
                Learn more
              </Button>
            </Block>
          </Layout>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default Solutions;
